import { ValidationResult } from 'core/interfaces/validationResult.model'
import { PermissionUtils } from 'core/utils'
import PropTypes from 'prop-types'

export type AuthInterface = {
  id: number
  email: string
  isAdmin: boolean
  permissions: Array<string>
  preferred_language: string
  preferred_theme: string
  createdAt: Date
}

class Auth {
  id: number
  email: string
  isAdmin: boolean
  permissions: Set<string>
  preferredLanguage: string
  preferredTheme: string
  createdAt: Date

  constructor(data: AuthInterface) {
    this.id = data.id
    this.email = data.email
    this.isAdmin = data.isAdmin
    this.preferredLanguage = data.preferred_language
    this.preferredTheme = data.preferred_theme
    this.permissions = new Set(data.permissions)
    this.createdAt = new Date(data.createdAt)
  }
  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      email: this.email,
      isAdmin: this.isAdmin,
      preferred_language: this.preferredLanguage,
      preferred_theme: this.preferredTheme,
      permissions: Array.from(this.permissions),
      createdAt: this.createdAt.toISOString(),
    })
  }

  updatePermissions(permissions: Array<string>): void {
    this.permissions = new Set(permissions)
  }

  static fromJSON(json: AuthInterface): Auth {
    return new Auth(json)
  }

  toString(): string {
    return `Auth: { id: ${this.id}, email: ${this.email}, isAdmin: ${this.isAdmin}, permissions: ${this.permissions}, preferredLanguage: ${this.preferredLanguage}, preferredTheme: ${this.preferredTheme}, createdAt: ${this.createdAt.toISOString()} }`
  }

  hasPermission(permission: string | Array<string>): boolean {
    if (this.isAdmin) return true
    if (Array.isArray(permission)) {
      return PermissionUtils.hasAnyPermission(this.permissions, permission)
    }
    return PermissionUtils.hasAnyPermission(this.permissions, [permission])
  }
}

export default Auth

export const AuthShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  preferredLanguage: PropTypes.string.isRequired,
  preferredTheme: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
})

export function isValidAuth(data: any): ValidationResult {
  let errors: string[] = []

  if (typeof data.id !== 'number') errors.push('id must be a number')
  if (typeof data.email !== 'string') errors.push('email must be a string')
  if (typeof data.preferred_theme !== 'string') errors.push('preferred_theme must be a string')
  if (typeof data.preferred_language !== 'string')
    errors.push('preferred_language must be a string')
  if (typeof data.isAdmin !== 'boolean') errors.push('isAdmin must be a boolean')
  if (!Array.isArray(data.permissions)) errors.push('permissions must be an array')
  if (typeof data.createdAt !== 'string') errors.push('createdAt must be a string')

  return {
    isValid: errors.length === 0,
    errors,
  }
}
