import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import i18next from 'i18next'

export const keyAppStorage = 'app-store'
export const keyAppStorageLanguage = 'app-store-language'

export const initialConfigs = {
  sideMenuOpen: true,
  language: i18next.language ?? 'fr',
}

interface Configs {
  sideMenuOpen: boolean
  language: string
}

interface AppStore {
  isLoading: boolean
  configs: Configs
  setConfigs: (configs: Partial<Configs>) => void
  setLoading: (isLoading: boolean) => void
  resetConfigs: () => void
}

const useAppStore = create(
  persist<AppStore>(
    (set, get) => ({
      isLoading: false,
      configs: get()?.configs || initialConfigs,
      setConfigs: (newConfigs: Partial<Configs>) =>
        set((state) => ({
          configs: {
            ...state.configs,
            ...newConfigs,
          },
        })),
      setLoading: (isLoading: boolean) =>
        set((state) => ({
          ...state,
          isLoading,
        })),
      resetConfigs: () =>
        set((state) => ({
          ...state,
          configs: initialConfigs,
        })),
    }),
    {
      name: keyAppStorage, // name of the item in the storage (must be unique)
    },
  ),
)

export default useAppStore
