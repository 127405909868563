export const transformPermissions = (permissions) => {
  const grouped = {}

  permissions.forEach(({ name }) => {
    const [, base] = name.split('-')
    // the base is the main permissions, e.g. 'create-users' users is the base
    // if the base is not present, then it is the main permission
    if (!base && !grouped[name]) {
      grouped[name] = []
    }
    if (base) {
      if (!grouped[base]) {
        grouped[base] = []
      }
      grouped[base].push(name)
    }
  })

  return grouped
}

// Check if the user has all the required permissions
export const hasAnyPermission = (permissions, requiredPermissions) => {
  return requiredPermissions.some((perm) => {
    return permissions.has(perm) || permissions.has(perm.split('-')[1])
  })
}

export const handleGroupChange = (selectedPermissions, group, perms) => {
  const newSelectedPermissions = new Set(selectedPermissions)
  const allSelected =
    selectedPermissions.has(group) || perms.every((perm) => newSelectedPermissions.has(perm))

  if (allSelected) {
    perms.forEach((perm) => newSelectedPermissions.delete(perm))
    // remove the main permission also
    newSelectedPermissions.delete(group)
  } else {
    // perms.forEach((perm) => newSelectedPermissions.add(perm))
    newSelectedPermissions.add(group)
  }
  return newSelectedPermissions
}

export const handlePermissionChange = (selectedPermissions, perms, perm) => {
  const newSelectedPermissions = new Set(selectedPermissions)
  const group = perm.split('-')[1]

  // if is to remove
  if (newSelectedPermissions.has(perm)) {
    newSelectedPermissions.delete(perm)
    return newSelectedPermissions
  }
  // if is to remove but the group is selected we must add all other permissions from the group besides the one selected that is to remove
  if (group && newSelectedPermissions.has(group)) {
    newSelectedPermissions.delete(group)
    perms.forEach((p) => {
      if (p !== perm) {
        newSelectedPermissions.add(p)
      }
    })
    return newSelectedPermissions
  }
  // if is to add
  newSelectedPermissions.add(perm)
  return newSelectedPermissions
}
