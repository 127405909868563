import { create } from 'zustand'

export const keyStorageTheme = 'app-store-theme'

interface ThemeStore {
  theme: string
  isDark: boolean
  setTheme: (theme: string) => void
  setPersistThemeFn: (fn: (theme: string) => void) => void
  persistTheme: (theme: string) => void
}
// reste

const defaultOSThemeIsDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches

const getThemeBasedOnOSTheme = () => {
  return defaultOSThemeIsDark() ? 'dark' : 'light'
}

const useThemeStore = create<ThemeStore>((set) => ({
  theme: getThemeBasedOnOSTheme(),
  isDark: defaultOSThemeIsDark(),
  persistTheme: () => {},
  setPersistThemeFn: (fn: (theme: string) => void) =>
    set((state) => ({
      ...state,
      persistTheme: fn,
    })),
  setTheme: (theme: string) => {
    set((state) => {
      state.persistTheme && state.persistTheme(theme) // Call the persist function if it exists
      return { ...state, theme, isDark: theme === 'dark' }
    })
  },
}))

export default useThemeStore
