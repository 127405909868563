import { debugLog } from 'core/utils'

class StorageManager {
  // Clear all data from session and local storage
  static clean(): void {
    debugLog('Cleaning all storage')
    localStorage.clear()
    sessionStorage.clear()
  }
}

export default StorageManager
