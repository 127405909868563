import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiService } from 'core/services/http.service'
import { debugLog } from 'core/utils'
// @ts-ignore
import Auth from 'data/models/Auth.model.ts'
// @ts-ignore
import AuthStorageManager from 'data/storage/auth.storage.ts'
// @ts-ignore
import StorageManager from 'data/storage/storage.ts'

const key = 'auth'
class _AuthProvider {
  _user: Auth | null

  constructor() {
    this._user = AuthStorageManager.get()
  }

  login = (onSuccess: Function, onError: Function) => {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: login,
      onSuccess: (response, variables, context) => {
        const authData = response.data.auth
        // transform the permissions
        authData.permissions = response.data.permissions

        this._user = AuthStorageManager.save(authData)

        // Update the user permissions query cache
        queryClient.setQueryData([key, 'permissions'], authData.permissions)

        onSuccess && onSuccess(response, variables, context)
      },
      onError: (error, variables, context) => onError && onError(error, variables, context),
    })
  }

  getPermissions = () => {
    return useQuery({
      queryKey: [key, 'permissions'],
      queryFn: async ({ queryKey }: any) => {
        debugLog('Fetching auth permissions')
        const response = await apiService.get('user/permissions')
        this._user?.updatePermissions(response.data.permissions)
        AuthStorageManager.save(this._user)
        return response.data.permissions
      },
      retry: 0,
    })
  }

  /*
   * Request to recover password
   * When the user forgot the password
   */
  recoverPassword = (onSuccess: Function, onError: Function) => {
    return useMutation({
      mutationFn: recoverPassword,
      onSuccess: (response, variables, context) => {
        onSuccess && onSuccess(response, variables, context)
      },
      onError: (error, variables, context) => onError && onError(error, variables, context),
    })
  }

  /*
   * Request to reset password
   * When the user is obligated to set a new password
   */
  resetPassword = (onSuccess: Function, onError: Function) => {
    return useMutation({
      mutationFn: makeResetPassword,
      onSuccess: (response, variables, context) => {
        onSuccess && onSuccess(response, variables, context)
      },
      onError: (error, variables, context) => onError && onError(error, variables, context),
    })
  }

  /*
   * Request to set user password
   * When the user account was created by an admin
   */
  setPassword = (onSuccess: Function, onError: Function) => {
    return useMutation({
      mutationFn: makeResetPassword,
      onSuccess: (response, variables, context) => {
        onSuccess && onSuccess(response, variables, context)
      },
      onError: (error, variables, context) => onError && onError(error, variables, context),
    })
  }

  /*
   * Request to reset password
   * When the user is obligated to set a new password
   */
  update = (onSuccess: Function, onError: Function) => {
    return useMutation({
      mutationFn: updateProfile,
      onSuccess: (response, variables, context) => {
        onSuccess && onSuccess(response, variables, context)
      },
      onError: (error, variables, context) => onError && onError(error, variables, context),
    })
  }

  updateDefaultLanguage = () => {
    return useMutation({
      mutationFn: switchLanguage,
      onSuccess: (response, variables, context) => {
        this._user!.preferredLanguage = response.data.preferred_language
        AuthStorageManager.save(this._user)
      },
    })
  }

  updateDefaultTheme = () => {
    return useMutation({
      mutationFn: toggleTheme,
      onSuccess: (response, variables, context) => {
        this._user!.preferredTheme = response.data.preferred_dark_theme ? 'dark' : 'light'
        AuthStorageManager.save(this._user)
      },
    })
  }

  // Make logout request
  logout = async () => {
    debugLog('Making logout request')

    // Clean the storage
    StorageManager.clean()

    if (this._user == null) return
    this._user = null

    return await apiService
      .post('/user/logout')
      .then(() => true)
      .catch(() => false)
  }

  // Get the user
  get user() {
    return this._user
  }

  // Used to check if the user is authenticated
  isAuthenticated = () => {
    return this._user !== null && this._user.email !== undefined
  }
}

const AuthProvider = new _AuthProvider()
export default AuthProvider

// Make login request
const login = async ({ data }: any) => {
  // clean all the storage
  StorageManager.clean()

  debugLog('User making login: ', data)
  return apiService.post(`user/login`, data)
}

const recoverPassword = async ({ data }: any) => {
  debugLog('Making recover password: ', data)
  const response = await apiService.post('/user/forgot-password', data)
  return response.data
}

const switchLanguage = async ({ newLanguage }: any) => {
  debugLog('Switching the user language to: ', { newLanguage })
  return apiService.post(`user/switch_language`, { preferred_language: newLanguage })
}

const toggleTheme = async ({ newValue }: any) => {
  debugLog('Toggling user theme to 0 = light, 1 = dark', { newValue })
  return apiService.post(`user/toggle_theme`, { preferred_dark_theme: newValue })
}

const makeResetPassword = async ({ data }: any) => {
  debugLog('Making reset password: ', data)
  const response = await apiService.post('/user/reset-password', data)
  return response.data
}

const updateProfile = async ({ data }: any) => {
  debugLog('Updating the user: ' + data.id, { data })
  return apiService.put(`user/update`, data)
}
