function arraysAreEqual(array1, array2) {
  // Check if the arrays are the same length
  if (array1.length !== array2.length) {
    return false
  }

  // Check if all elements are equal
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].toString() !== array2[i].toString()) {
      return false
    }
  }

  // If we haven't returned false yet, arrays are equal
  return true
}

export { arraysAreEqual }
