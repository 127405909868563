import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSpinner } from '@coreui/react'
import useThemeStore from 'core/store/theme.store'

// Full screen loading component
const LoadingFullScreen = ({ loading = true, children }) => {
  const { theme } = useThemeStore()

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [loading])

  return (
    <>
      {children}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
            color: theme === 'dark' ? '#fff' : '#000',
            zIndex: 9999,
          }}
        >
          <CSpinner color="primary" variant="grow" />
        </div>
      )}
    </>
  )
}

export default LoadingFullScreen

LoadingFullScreen.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element,
}
