// @ts-ignore
import Auth, { isValidAuth } from 'data/models/Auth.model.ts'

// Key for storing authentication data in local storage
const STORAGE_KEY: string = process.env.REACT_APP_AUTH_STORAGE_KEY!

class AuthStorageManager {
  // Save mange authentication data to be saved on local storage
  static save(authData: any): Auth | null {
    // if is already an instance of Auth, just save it
    if (authData instanceof Auth) {
      this.persist(authData)
      return authData
    }
    const validation = isValidAuth(authData)
    if (validation.isValid) {
      let auth = Auth.fromJSON(authData)
      this.persist(auth)
      // process the user permissions
      // defineAbilityFor(auth)
      return auth
    }

    throw new Error('Invalid authentication data' + validation.errors.join(', '))
  }

  // persist the authenticator data to local storage
  private static persist(authData: Auth): void {
    // get the current authentication data from storage
    localStorage.setItem(STORAGE_KEY, authData.toJSON())
  }

  // Retrieve authentication data from local storage
  static get(): Auth | null {
    const authDataString = localStorage.getItem(STORAGE_KEY!)
    // console.log('Auth Storage Manager: ', authDataString)
    if (authDataString) {
      return Auth.fromJSON(JSON.parse(authDataString))
    }

    return null
  }

  // Clear authentication data from local storage
  static clean(): void {
    localStorage.removeItem(STORAGE_KEY)
  }
}

export default AuthStorageManager
