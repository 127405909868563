import axios from 'axios'
import { debugLog } from 'core/utils'
import { ExpectedError, LaravelError } from 'core/utils/laravel_errors.utils'
import AuthProvider from 'data/providers/auth.provider.ts'

class HTTPService {
  constructor() {
    // for httpOnly cookies
    axios.defaults.withCredentials = true

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Signature': process.env.REACT_APP_API_KEY,
        // this is not secure for XSS attacks
        // Authorization: 'Bearer ' + localStorage.getItem('authToken'),
      },
    })
  }

  async download(path) {
    try {
      const response = await this.getBlob(path)
      console.log(response)
      console.log(response.headers)
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = response.headers['x-info'] ?? 'file'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      throw error
    }
  }

  async getBlob(path, params = null) {
    try {
      let urlSearchParams = new URLSearchParams()

      // Convert array params to array format
      for (let key in params) {
        if (Array.isArray(params[key])) {
          // eslint-disable-next-line no-loop-func
          params[key].forEach((value) => urlSearchParams.append(`${key}[]`, value))
        } else {
          urlSearchParams.append(key, params[key])
        }
      }

      if (urlSearchParams.size > 0) {
        // check if ? is already in the path
        if (path.includes('?')) {
          path = `${path}&${urlSearchParams}`
        } else {
          path = `${path}?${urlSearchParams}`
        }
      }
      debugLog('GET BLOB', path)
      const response = await this.api.get(`${path}`, { responseType: 'blob' })
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
    // return this.get(path, { responseType: 'blob' })
  }

  async get(path, params = null, additionalRequestConfigs = {}) {
    try {
      let urlSearchParams = new URLSearchParams()

      // Convert array params to array format
      for (let key in params) {
        if (Array.isArray(params[key])) {
          // eslint-disable-next-line no-loop-func
          params[key].forEach((value) => urlSearchParams.append(`${key}[]`, value))
        } else {
          urlSearchParams.append(key, params[key])
        }
      }

      if (urlSearchParams.size > 0) {
        // check if ? is already in the path
        if (path.includes('?')) {
          path = `${path}&${urlSearchParams}`
        } else {
          path = `${path}?${urlSearchParams}`
        }
      }
      const response = await this.api.get(`${path}`, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async post(path, data, additionalRequestConfigs = {}) {
    try {
      const response = await this.api.post(`${path}`, data, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async put(path, data, additionalRequestConfigs = {}) {
    try {
      const response = await this.api.put(`${path}`, data, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async delete(path, additionalRequestConfigs = {}) {
    try {
      const response = await this.api.delete(`${path}`, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async handleResponse(response) {
    if (response.status < 200 || response.status >= 300) {
      debugLog('HTTPService not handled error: ', response)
      // throw the error as has it is
      throw response
    }
    return response
  }

  async handleErrors(error) {
    let response = error.response

    // forbidden error
    if (response?.status === 403) {
      if (process.env.REACT_APP_APP_ENV !== 'development') {
        window.location.href = '/forbidden'
        return
      }
      throw error
    }

    // unauthorized error
    if (response.status === 401) {
      const pathname = window.location.pathname

      if (pathname === '/login' || pathname.startsWith('/reset_password/')) {
        throw new ExpectedError('invalid_credentials', response.status)
      }

      // if (process.env.REACT_APP_APP_ENV === 'development') {
      //   throw error
      // }

      await AuthProvider.logout()
      // Redirect to login
      window.location.href = '/login'
      return
    }
    // Handle 404 request errors
    if (response.status === 404) {
      debugLog('HTTPService 404 error: ', response)
      if (process.env.REACT_APP_APP_ENV !== 'development') {
        window.location.href = '/404'
        return
      }
      throw error
    }
    // Handle 422 request errors
    if (response.status === 422) {
      let laravelErrors = response.data.errors
      throw new LaravelError('Request validation error', response.status, laravelErrors)
    }

    // Handle 429 request errors (Too many requests)
    if (response.status === 429) {
      if (window.location.pathname === '/login') {
        throw new ExpectedError('too_many_requests', response.status)
      }
      throw error
    }

    // Handle 452 custom messages errors
    if (response.status === 452) {
      throw new ExpectedError(response.data.message, response.status, response.data.type)
    }

    // not handled errors
    throw error
  }
}

export const apiService = new HTTPService()
