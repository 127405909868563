import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from 'providers/AuthContextProvider'
import { useNavigateTo } from 'hooks/navigation.hook'
import PagesLayout from '../layout/PagesLayout'

const NotAuthenticatedMiddleware = ({ children }) => {
  const { auth } = useAuth()
  const navigate = useNavigateTo()

  useEffect(() => {
    if (auth?.email !== undefined) {
      navigate('/')
    }
  }, [auth]) // eslint-disable-line react-hooks/exhaustive-deps

  if (auth?.email !== undefined) return null

  return <PagesLayout>{children}</PagesLayout>
}

export default NotAuthenticatedMiddleware

NotAuthenticatedMiddleware.propTypes = {
  children: PropTypes.element,
}
