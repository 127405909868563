import React, { createContext, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { arraysAreEqual } from 'core/utils/lists.utils'

// Create a context to manage query parameters
const QueryParamsContext = createContext()

// Custom hook to use the query parameters context
export const useQueryParams = () => useContext(QueryParamsContext)

// Query parameters provider component
const QueryParamsProvider = ({ children }) => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search))

  const applyQueryParams = (newUrl, params) => {
    // Update the browser's history and the URL without reloading the page
    window.history.pushState({}, '', newUrl)
    // like this we can update the query params without reloading the page
    setQueryParams(new URLSearchParams(params))
  }

  // used to update the query params when we have multiple values for the same param
  const updateQueryParamsForMultiple = (param, values) => {
    const searchParams = new URLSearchParams(window.location.search)

    // remove the param from the searchParams
    searchParams.delete(param)
    // if there are values to add
    if (values.length !== 0) {
      // add all the values to the searchParams
      for (let index = 0; index < values.length; index++) {
        const value = values[index]
        if (typeof value === 'object' && value !== null) {
          searchParams.append(param, value.value)
        } else {
          searchParams.append(param, value)
        }
      }
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`

    applyQueryParams(newUrl, searchParams)
  }

  const checkIfIsAReallyChange = (param, value) => {
    // if already exists and the value is undefined is to be removed
    if (queryParams.has(param) && value === undefined) {
      return true
    }

    let isValidValue = value !== undefined && value !== null && value !== ''
    if (Array.isArray(value) && value.length === 0) {
      isValidValue = false
    }

    // if not valid value return false
    if (!isValidValue) {
      return false
    }

    // if the param does not exists and the value is not undefined, empty, or null is to be added
    if (!queryParams.has(param) && isValidValue) {
      return true
    }

    // if exists and the value is not undefined, empty, or null is to be updated if the value is different
    if (queryParams.has(param) && value !== undefined) {
      const currentParam = queryParams.getAll(param)
      if (Array.isArray(value)) {
        return !arraysAreEqual(value, currentParam)
      }
      return value.toString() !== currentParam[0]
    }
    return true
  }

  const setAQueryParams = (param, value) => {
    if (!checkIfIsAReallyChange(param, value)) {
      return
    }
    // Create a URLSearchParams object from the current search string
    const searchParams = new URLSearchParams(window.location.search)

    if (value === undefined || value === null) {
      // If the value is undefined or null, remove the param
      searchParams.delete(param)
    } else {
      // Otherwise, set (add/edit) the param
      if (Array.isArray(value)) {
        updateQueryParamsForMultiple(param, value)
        return
      }
      if (typeof value === 'object' && value !== null) {
        searchParams.set(param, value.value)
      } else {
        searchParams.set(param, value)
      }
    }

    // Create the new URL string
    const newUrl = `${window.location.pathname}${
      searchParams.size > 0 ? `?${searchParams.toString()}` : ''
    }`

    applyQueryParams(newUrl, searchParams)
  }

  const updateQueryParams = (newParams) => {
    if (newParams === undefined) {
      window.history.pushState({}, '', location.pathname)
      setQueryParams(new URLSearchParams(location.search))
      return
    }

    applyQueryParams(`${location.pathname}?${newParams}`, newParams)
  }

  return (
    <>
      <QueryParamsContext.Provider
        value={{ queryParams, setQueryParams: updateQueryParams, setAQueryParams }}
      >
        {children}
      </QueryParamsContext.Provider>
    </>
  )
}

export default QueryParamsProvider

QueryParamsProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
