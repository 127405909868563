import React, { useState, createContext, useContext } from 'react'

import PropTypes from 'prop-types'
import ConfirmationModal from 'components/models/ConfirmModal'

const ConfirmationModalContext = createContext()

export const useConfirmationModal = () => useContext(ConfirmationModalContext)

export const ConfirmationModalProvider = ({ children }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [confirmationModalData, setConfirmationModalData] = useState({})

  const showConfirmationModal = (data) => {
    setConfirmationModalData(data)
    setIsConfirmationModalOpen(true)
  }

  const hideConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
    setConfirmationModalData({})
  }

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmationModal, hideConfirmationModal }}>
      {children}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={confirmationModalData.title}
        message={confirmationModalData.message}
        theme={confirmationModalData.theme}
        onConfirm={confirmationModalData.onConfirm}
        onCancel={hideConfirmationModal}
        textConfirmButton={confirmationModalData.textConfirmButton}
        textCancelButton={confirmationModalData.textCancelButton}
      />
    </ConfirmationModalContext.Provider>
  )
}

ConfirmationModalProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
