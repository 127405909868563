import React from 'react'

import PropTypes from 'prop-types'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import { useT } from 'hooks/translation.hook'

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  theme = 'primary',
  onConfirm,
  onCancel,
  textConfirmButton,
  textCancelButton,
}) => {
  const t = useT()

  const handleOnConfirm = () => {
    onConfirm()
    onCancel()
  }
  return (
    <CModal
      id="confirmationModal"
      alignment="center"
      visible={isOpen}
      onClose={onCancel}
      aria-labelledby="confirmationModal"
      // Disables closing the modal by clicking on the backdrop
      backdrop="static"
    >
      <CModalHeader closeButton>
        <CModalTitle className={theme}>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{message}</CModalBody>
      <CModalFooter>
        <CButton color={theme} onClick={handleOnConfirm}>
          {textConfirmButton ?? t('confirm')}
        </CButton>
        <CButton color="secondary" onClick={onCancel}>
          {textCancelButton ?? t('cancel')}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default ConfirmationModal

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  theme: PropTypes.string,
  textConfirmButton: PropTypes.string,
  textCancelButton: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}
