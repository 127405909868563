import React, { createContext, useContext } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { ConfirmationModalProvider } from './ConfirmationModalProvider'
import { AuthContextProvider } from './AuthContextProvider'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify'
import { toastDefaultDuration, toastDefaultPosition } from 'core/constants/config.constants'
import useThemeStore from 'core/store/theme.store'
import 'react-toastify/dist/ReactToastify.css'
import QueryParamsProvider from './QueryParamsContextProvider'

// Create a new QueryClient instance with default options
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Refetch data when the window is focused
      refetchOnWindowFocus: true,

      // Refetch data when the component is mounted
      refetchOnMount: true,

      // Always refetch data when the network connection is reestablished
      refetchOnReconnect: 'always',

      // Retry failed queries up to 1 time after the first failure
      retry: 1,

      // Delay between retries in milliseconds (1 second)
      retryDelay: 1000,

      // Data is considered fresh for 10 seconds
      staleTime: 10000,

      // Cache data for 30 seconds (30000 milliseconds) before garbage collecting it
      cacheTime: 30000,

      // Queries are enabled by default
      enabled: true,

      // Do not use React Suspense for this query
      suspense: false,

      // Provide initial data for the query, returning an empty users array
      // initialData: () => ({ users: [] }),
    },
  },
})

const GeralContext = createContext()

//  Provider component that wraps your app and makes
export const useGeralContext = () => useContext(GeralContext)

//  Provider component that wraps your component to provide user data
export const GeralContextProvider = ({ children }) => {
  const { theme } = useThemeStore()
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <QueryParamsProvider>
          <>
            <ConfirmationModalProvider>{children}</ConfirmationModalProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer
              position={toastDefaultPosition}
              autoClose={toastDefaultDuration}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme={theme}
              limit={2}
            />
          </>
        </QueryParamsProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  )
}

GeralContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
