import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

//Initialize language system
import './i18n'
import './scss/style.scss'
import LoadingFullScreen from './components/loadings/LoadingFullScreen'
import useAppStore from './core/store/app.store'
import { GeralContextProvider } from 'providers/GeralContextProvider'
import NotAuthenticatedMiddleware from 'middlewares/NotAuthenticatedMiddleware'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/dashboard/DashboardLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login.page'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword.page'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404.page'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403.page'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500.page'))

const App = () => {
  const { isLoading } = useAppStore()
  const loadingComponent = <LoadingFullScreen />

  return (
    <LoadingFullScreen loading={isLoading}>
      <BrowserRouter>
        <GeralContextProvider>
          <Suspense fallback={loadingComponent}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={
                  <NotAuthenticatedMiddleware>
                    <Login />
                  </NotAuthenticatedMiddleware>
                }
              />
              <Route
                path="/reset-password/:token"
                name="Reset Password Page"
                element={
                  <NotAuthenticatedMiddleware>
                    <ResetPassword />
                  </NotAuthenticatedMiddleware>
                }
              />

              <Route
                path="/new-account/:token"
                name="New account Page"
                element={
                  <NotAuthenticatedMiddleware>
                    <ResetPassword />
                  </NotAuthenticatedMiddleware>
                }
              />

              <Route path="/forbidden" name="Page 403" element={<Page403 />} />
              <Route path="/404" name="Page 404" element={<Page404 />} />
              <Route path="/500" name="Page 500" element={<Page500 />} />

              {/* Entry Point */}
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </GeralContextProvider>
      </BrowserRouter>
    </LoadingFullScreen>
  )
}

export default App
