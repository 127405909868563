import React from 'react'

import PropTypes from 'prop-types'

// This is the layout for the pages that are not part of the dashboard
const PagesLayout = ({ children }) => {
  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">{children}</div>
  )
}

export default PagesLayout

PagesLayout.propTypes = {
  children: PropTypes.element,
}
